/*==============================================================================
// File:        _map.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared map component - SASS
//============================================================================*/

.zen-map {
  @include fluid-prop(height, var(--rk-viewport-full-min), var(--rk-viewport-xl), 350px, 850px);

  overflow: hidden;
  margin: var(--rk-m-xl) 0;
  width: 100%;

  .leaflet-popup-content {
    width: 310px !important;
    padding: 0;
  }

  @include adjoin("leaflet-container") {
    h4 {
      font-size: var(--rk-font-size-xl);
      color: var(--rk-secondary);
      text-align: left;
      padding: 0;
    }

    p {
      padding: 0 0 var(--rk-m-df);
      text-align: left;
    }

    a {
      text-align: center;
    }
  }

  /*==============================================================================
// Elements
//============================================================================*/

  @include element("info") {
    color: var(--rk-secondary);
    padding: var(--rk-p-df);
  }

  @include element("sub-title") {
    text-transform: uppercase;
  }
}
