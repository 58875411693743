.zen-header {
  z-index: var(--rk-z-index-header) !important;
  position: relative;
  padding: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("main") {
    justify-content: space-between;
    background: rgba($base-white, .8);
    backdrop-filter: blur(10px);
    align-items: center;
    padding: 1.5rem 0;
    z-index: var(--rk-z-index-top) !important;
    position: fixed;
    display: flex;
    gap: 25px;
    left: 0;
    right: 0;
    top: 0;

    .zen-icon.icon-sys-hamburger {
      color: #444;
    }
  }

  @include element("brand") {
    img {
      width: 130px;
    }

    @media (min-width: 1200px) {
      img {
        width: 150px;
      }
    }
  }
}

.zen-menu-open {
  .zen-header {
    z-index: var(--rk-z-index-header) !important;
  }
}

.scrolled .zen-header__main {
  box-shadow: 0 0 20px rgba($base-black, .1);
}
