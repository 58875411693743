/*==============================================================================
// File:        _input.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared input component - SASS
//============================================================================*/

.zen-input {
  border: 1px solid var(--rk-base-grey-darker);
  background: var(--rk-base-off-white-dark);
  font-size: var(--rk-font-size-df);
  line-height: 1.5em;
  border-radius: 0;
  padding: 12px;
  height: auto;

  &:focus {
    border-color: var(--rk-primary);
    box-shadow: none;
    outline: none;
    color: var(--rk-text);
  }

  &::-webkit-input-placeholder {
    color: var(--rk-base-grey-dark);
  }

  &:-ms-input-placeholder {
    color: var(--rk-base-grey-dark);
  }

  &::placeholder {
    color: var(--rk-base-grey-dark);
  }
}

/*------------------------------------------------------------------------------
// Extend input element & modifiers
//----------------------------------------------------------------------------*/

%zen-input {
  @extend .zen-input;
}
