/*==============================================================================
// File:        _gallery.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared gallery component - SASS
//============================================================================*/

.zen-gallery {
  border-bottom: 1px solid var(--rk-default);
  margin: 0 auto var(--rk-m-xl);
  padding: 0 0 var(--rk-p-md);
  max-width: 750px;
}

.zen-gallery--large {
  margin: var(--rk-m-xl) auto;
  max-width: 100%;
  border: none;
}

.zen-gallery--fade-transition .carousel-item {
  transition-property: opacity;
  transition-duration: .9s;
  opacity: 0;
}

.zen-gallery--fade-transition .carousel-item-prev.carousel-item-right,
.zen-gallery--fade-transition .carousel-item-next.carousel-item-left,
.zen-gallery--fade-transition .carousel-item.active {
  opacity: 1;
}

.zen-gallery--fade-transition .active.carousel-item-right,
.zen-gallery--fade-transition .active.carousel-item-left {
  opacity: 0;
}

.zen-gallery--fade-transition .active.carousel-item-left,
.zen-gallery--fade-transition .active.carousel-item-prev,
.zen-gallery--fade-transition .carousel-item.active,
.zen-gallery--fade-transition .carousel-item-next,
.zen-gallery--fade-transition .carousel-item-prev {
  transform: translate3d(0, 0, 0);
  transform: translateX(0);
}
