/*==============================================================================
// File:        _rte.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Rich text editor overrides - SASS
//============================================================================*/

.zen-rte {
  padding: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("space-headings") {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 0 1em;
    }
  }

  @include modifier("text-shadow") {
    p,
    li,
    span {
      text-shadow: 0 0 20px rgba($base-black, .2);
    }
  }

  @include modifier("default-list-ticks") {
    ul {
      @extend %zen-list--default-ticks;
    }
  }

  @include modifier("default-list-crosses") {
    ul {
      @extend %zen-list--default-crosses;
    }
  }
}
