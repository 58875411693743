/*==============================================================================
// File:        _hero.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared hero component - SASS
//============================================================================*/
.zen-hero {
  position: relative;
  margin: var(--rk-m-full-lg);
}

//==================================================================
// Elements
//==================================================================

.zen-hero__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--rk-base-black);
  background: linear-gradient(0deg, rgba(0, 0, 0, 100%) 0%, rgba(0, 0, 0, 2.7%) 100%);
}

.zen-hero__caption h1 {
  color: var(--rk-base-white);
}

@media all and (max-width: 600px) {
  .zen-hero__caption {
    margin-top: -100px;
  }
}
