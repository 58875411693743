/*==============================================================================
// File:        _footer.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main footer styles for the website - SASS
//============================================================================*/

.zen-footer {
  background: var(--rk-secondary-dark);
  z-index: var(--rk-z-index-bottom);
  color: var(--rk-base-white);
  position: relative;

  .zen-list li {
    font-size: var(--rk-font-size-xl);
    margin: 0 0 var(--rk-m-df);
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("main") {
    @include media-breakpoint-down(lg) {
      text-align: center;

      .sppb-text-left {
        text-align: center;
      }

      .zen-footer-menu .zen-list {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        column-gap: normal;
        column-rule: none;
        column-count: 1;
        max-width: 100%;
        margin: 0 auto;
        display: flex;

        li {
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  @include element("signup") {
    background: var(--rk-primary);
    color: var(--rk-base-white);

    .btn {
      @extend %zen-btn--alternate;

      width: auto;
    }
  }

  @include element("signup-container") {
    padding: var(--rk-p-df) var(--rk-p-md) 50px;
    max-width: 600px;
    margin: 0 auto;
  }

  @include element("copyright") {
    text-transform: uppercase;
    font-size: var(--rk-font-size-sm);
    background: var(--rk-secondary);
    text-align: center;
    padding: var(--rk-p-df);
  }
}
