/*==============================================================================
// File:        _list.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared list component - SASS
//============================================================================*/

.zen-list {
  padding: 0;
  margin: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("no-type") {
    list-style-type: none;
  }

  @include modifier("default") {
    padding: 0 0 0 var(--rk-p-df);
    align-items: center;
    position: relative;
    margin: 0 0 var(--rk-m-df);

    li {
      padding: 0 0 var(--rk-p-xs) var(--rk-p-xs);
      align-items: center;
      margin: 0 0 var(--rk-m-xs);
      list-style: none;
      display: flex;

      &:before {
        position: absolute;
        color: var(--rk-primary);
        font-weight: bold;
        content: "\2022";
        font-size: 26px;
        margin: 0;
        left: 0;
      }
    }
  }

  @include modifier("underline") {
    padding: 0;
    margin: 0;
    align-items: start;
    position: relative;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      padding: 5px 0;
      margin: 0 0 5px;
      list-style: none;
      display: flex;
      border-bottom: 2px solid #F3EBDE;
      font-size: 18px;
    }
  }

  @include modifier("border-bottom") {
    padding: 0;
    margin: 0;
    align-items: start;
    position: relative;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      padding: 5px 0;
      margin: 0 0 5px;
      list-style: none;
      display: flex;
      border-bottom: 2px solid #F3EBDE;
      font-size: 18px;

      a {
        font-weight: 500;
      }
    }
  }

  @include modifier("default-ticks") {
    @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 16px, 18px);

    padding: 0 0 0 var(--rk-p-lg);
    position: relative;
    margin: 0 0 var(--rk-m-xl);

    ul {
      list-style: none;
      padding-left: var(--rk-p-sm);
    }

    li {
      padding: 0 0 var(--rk-p-md) var(--rk-p-lg);
      margin: 0 0 var(--rk-m-xs);

      &::marker {
        content: none;
        display: none;
      }

      &:before {
        @extend %fontello;

        background: radial-gradient(white 50%, transparent 0%);
        border-radius: 100%;
        font-size: var(--rk-icon-size-xs);
        position: absolute;
        margin: 2px 0 0;
        color: var(--rk-primary);
        content: "\e851";
        left: 0;
      }
    }
  }

  @include modifier("default-crosses") {
    @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 16px, 18px);

    padding: 0 0 0 var(--rk-p-lg);
    position: relative;
    margin: 0 0 var(--rk-m-xl);
    list-style: none;

    ul {
      list-style: none;
      padding-left: var(--rk-p-sm);
    }

    li {
      padding: 0 0 var(--rk-p-md) var(--rk-p-lg);
      list-style-type: none;
      margin: 0 0 var(--rk-m-xs);

      &::marker {
        content: none;
        display: none;
      }

      &:before {
        @extend %fontello;

        background: radial-gradient(white 50%, transparent 0%);
        border-radius: 100%;
        font-size: var(--rk-icon-size-xs);
        position: absolute;
        margin: 2px 0 0;
        color: var(--rk-primary);
        content: "\e850";
        left: 0;
      }
    }
  }

  @include modifier("inline") {
    li {
      margin: 0 var(--rk-list-m-df) 0 0;
      display: inline-block;
    }
  }

  @include modifier("space-between") {
    justify-content: space-between;
    align-items: center;
    display: flex;

    li {
      margin: 0 var(--rk-list-m-xl) 0 0;
      display: inline-block;
    }
  }

  /*------------------------------------------------------------------------------
	// Standard menu list item
	//----------------------------------------------------------------------------*/
  @include modifier("menu") {
    text-transform: none;
    flex-direction: row;
    height: auto;

    li {
      display: block;
      margin: 0 var(--rk-p-df) 0 0;
    }
  }

  @include modifier("menu-dropdown") {
    text-transform: none;

    li {
      display: block;
      margin: 0;
    }
  }

  @include modifier("menu-main") {
    justify-content: end;
    display: flex;
    height: 50px;

    li {
      align-items: center;
      margin: 0 1px 0 0;
      display: flex;
    }
  }

  @include modifier("menu-sub") {
    @include insert-shadow(0, 0, 10px, -2px, var(--rk-shadow));

    background: var(--rk-base-white);
    border-radius: 0;
    min-width: 200px;
    color: var(--rk-primary);
    padding: var(--rk-p-sm);
    border: none;
    margin: 0;

    &:before {
      @include pseudo;

      border-bottom: 10px solid #FFF;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      top: -10px;
      height: 0;
      left: 30%;
      width: 0;
    }

    li {
      border-bottom: 1px solid var(--rk-primary);
      display: flex;

      &:last-child {
        border: none;
      }
    }
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/
  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend list element & modifiers
//----------------------------------------------------------------------------*/

%zen-list {
  @extend .zen-list;
}

%zen-list--default {
  @extend .zen-list--default;
}

%zen-list--default-ticks {
  @extend .zen-list--default-ticks;
}

%zen-list--default-crosses {
  @extend .zen-list--default-crosses;
}

%zen-list--inline {
  @extend .zen-list--inline;
}

%zen-list--space-between {
  @extend .zen-list--space-between;
}

%zen-list--menu-main {
  @extend .zen-list--menu-main;
}

%zen-list--menu-sub {
  @extend .zen-list--menu-sub;
}
