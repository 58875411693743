/*==============================================================================
// File:        _select.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared select component - SASS
//============================================================================*/

.zen-select {
  margin: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("span") {
    @include truncate(100%);

    border-radius: var(--rk-border-radius-df);
    padding: 17.5px 35px 17.5px 15px;
    border: 2px solid var(--rk-alternate);
    background: var(--rk-base-white);
    display: inline-block;
    text-rendering: auto;
    font-size: inherit;
    position: relative;
    text-align: center;
    line-height: 1em;
    color: var(--rk-text);
    width: 100%;
    z-index: 1;

    &:after {
      @extend %fontello;

      font-size: var(--rk-icon-size-xs);
      position: absolute;
      color: var(--rk-secondary);
      margin-left: 10px;
      content: "\e804";
      right: 8px;
    }

    &:focus {
      border-color: var(--rk-primary);
      box-shadow: none;
      outline: none;
    }

    @include adjoin("focus") {
      border-color: var(--rk-primary);
      box-shadow: none;
      outline: none;
    }
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("default") {
    @include fluid-prop(
      font-size,
      var(--rk-viewport-full-min),
      var(--rk-viewport-xl),
      var(--rk-font-size-xs),
      var(--rk-font-size-md)
    );

    text-transform: uppercase;
    position: relative;
    color: var(--rk-text);
  }

  @include modifier("large") {
    @include fluid-prop(
      font-size,
      var(--rk-viewport-full-min),
      var(--rk-viewport-xl),
      var(--rk-font-size-sm),
      var(--rk-font-size-xl)
    );
  }

  @include modifier("small") {
    padding: var(--rk-p-xs) 35px var(--rk-p-xs) 8px;
    max-height: 25px;
    font-size: 12px;
  }

  @include modifier("w-auto") {
    width: auto;
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-select {
  @extend .zen-select;
}

%zen-select--default {
  @extend .zen-select--default;
}

%zen-select--large {
  @extend .zen-select--large;
}

%zen-select--small {
  @extend .zen-select--small;
}

%zen-select--w-auto {
  @extend .zen-select--w-auto;
}

%zen-select__span {
  @extend .zen-select__span;
}

/*------------------------------------------------------------------------------
// Default AngularJS select styling
//----------------------------------------------------------------------------*/

.selectlist {
  @extend %zen-select--default;

  &:hover {
    cursor: pointer;
  }

  span {
    @extend %zen-select__span;
  }

  select {
    position: absolute;
    border: none;
    height: 90%;
    width: 100%;
    opacity: 0;
    z-index: 2;
    left: 0;
    top: 0;
  }
}

.zen-styled-select {
  border-color: var(--rk-base-grey);
  background: var(--rk-base-white);
  opacity: 0;
}
