/*==============================================================================
// File:        _title.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared title component - SASS
//============================================================================*/

.zen-title {
  padding: 0;

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("default") {
    color: var(--rk-default);
  }

  @include modifier("light") {
    color: var(--rk-base-white);
  }

  @include modifier("dark") {
    color: var(--rk-text);
  }

  @include modifier("primary") {
    color: var(--rk-primary);
  }

  @include modifier("primary-dark") {
    color: var(--rk-primary-dark);
  }

  @include modifier("primary-light") {
    color: var(--rk-primary-light);
  }

  @include modifier("primary-lighter") {
    color: var(--rk-primary-lighter);
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);
  }

  @include modifier("hr-primary") {
    margin: var(--rk-m-full-xl);

    &:after {
      @include pseudo;

      border-bottom: 1px solid var(--rk-primary);
      margin: var(--rk-m-df) auto;
      width: 100px;
      right: 0;
      left: 0;
    }
  }

  @include modifier("category") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 21px);

    border-bottom: 1px solid var(--rk-secondary);
    padding: 0 0 var(--rk-p-sm);
    margin: var(--rk-m-full-md);
    font-weight: bold;
    color: var(--rk-secondary);
  }

  @include modifier("booking") {
    letter-spacing: normal;
    margin: var(--rk-m-sm) 0 var(--rk-m-xs);
    text-transform: none;
    font-style: italic;
    color: var(--rk-text);
  }
}

/*------------------------------------------------------------------------------
// Extend title element & modifiers
//----------------------------------------------------------------------------*/

%zen-title {
  @extend .zen-title;
}

%zen-title--light {
  @extend .zen-title--light;
}

%zen-title--dark {
  @extend .zen-title--dark;
}

%zen-title--primary {
  @extend .zen-title--primary;
}

%zen-title--primary-dark {
  @extend .zen-title--primary-dark;
}

%zen-title--primary-light {
  @extend .zen-title--primary-light;
}

%zen-title--primary-lighter {
  @extend .zen-title--primary-lighter;
}

%zen-title--secondary {
  @extend .zen-title--secondary;
}

%zen-title--category {
  @extend .zen-title--category;
}
