/*==============================================================================
// File:        _blog.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main blog styles for the website - SASS
//============================================================================*/

.zen-blog {
  overflow: hidden;
  padding: 0;

  @include element("card-more") {
    .card-image {
      height: 110px;
      background-size: cover;
      background-position: center;
    }
  }

  // ------------------------------------------------//
  // BLOG SINGLE ARTICLE STYLES
  // ------------------------------------------------ //

  @include element("article-pagination") {
    .pagenavigation {
      @extend .zen-list;
      @extend .zen-list--inline;

      justify-content: center;
      align-items: center;
      padding: $p-lg 0;
      display: flex;

      .pagination,
      li {
        .next,
        .previous {
          @extend .zen-btn;
          @extend .zen-btn--alternate;
        }

        .icon-chevron-left,
        .icon-chevron-right {
          @extend %fontello;

          vertical-align: middle;
          padding: 0;
        }
      }
    }
  }
}
