/*==============================================================================
// File:        _card.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared card component - SASS
//============================================================================*/

.zen-card {
  @include insert-shadow(0, 0, 15px, -4px, var(--rk-box-shadow));

  border: 2px solid var(--rk-grey-lightest);
  background: var(--rk-base-white);
  border-radius: 0;
  margin: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("image-container") {
    padding: var(--rk-p-df);
  }

  @include element("image") {
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 0;
    margin: 0;

    @include modifier("max-height") {
      max-height: 285px;
      overflow: hidden;
    }
  }

  @include element("title") {
    margin: var(--rk-m-md) 0;
  }

  @include element("header") {
    background: var(--rk-light);
    border-radius: 0;
    border: none;
  }

  @include element("body") {
    padding: var(--rk-p-df);
  }

  @include element("content") {
    border-top: 1px solid var(--rk-secondary);
    padding: var(--rk-p-sm) 0;

    ul {
      @extend %zen-list;
      @extend %zen-list--default;
    }
  }

  @include element("description") {
    padding: var(--rk-p-df);
  }

  @include element("info") {
    border-bottom: 1px solid var(--rk-base-grey-lighter);
    border-top: 1px solid var(--rk-base-grey-lighter);
    text-transform: capitalize;
    margin: 0 var(--rk-m-df) var(--rk-m-df);
    padding: var(--rk-p-df);
  }

  @include element("price") {
    justify-content: space-between;
    background: var(--rk-alternate-light);
    border: 1px solid var(--rk-alternate);
    margin: 0 var(--rk-m-df);
    padding: var(--rk-p-df);
    display: flex;
  }

  @include element("footer") {
    background: var(--rk-base-white);
    text-align: center;
    padding: var(--rk-p-df);
    border: none;
  }

  /*==============================================================================
    // Modifiers
    //============================================================================*/

  @include modifier("related") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    margin: 0 auto var(--rk-m-xl);
    max-width: 435px;

    &:focus,
    &:hover {
      @include insert-shadow(0, 0, 15px, -2px, var(--rk-shadow));

      cursor: pointer;
    }
  }

  @include modifier("search") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    &:focus,
    &:hover {
      @include insert-shadow(0, 0, 15px, -2px, var(--rk-shadow));

      cursor: pointer;
    }
  }

  @include modifier("exclusive") {
    border: 1px solid var(--rk-primary);
  }

  @include modifier("note") {
    padding: var(--rk-p-lg) var(--rk-p-xl);
  }

  @include modifier("full-height") {
    /*Rollback Display*/
    display: block;
    display: grid;
    height: 100%;
  }

  @include modifier("dates-prices") {
    border: 6px solid var(--rk-alternate);
    background: var(--rk-base-white);
  }

  @include modifier("dates-prices-alt") {
    background: var(--rk-alternate);
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-card {
  @extend .zen-card;
}

%zen-card__image {
  @extend .zen-card__image;
}

%zen-card__title {
  @extend .zen-card__title;
}

%zen-card__header {
  @extend .zen-card__header;
}

%zen-card__body {
  @extend .zen-card__body;
}

%zen-card__info {
  @extend .zen-card__info;
}

%zen-card__footer {
  @extend .zen-card__footer;
}
