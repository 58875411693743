/*==============================================================================
// File:        _pill.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared pill component - SASS
//============================================================================*/

.zen-pill {
  border-radius: var(--rk-border-radius-df);
  background: rgba(225, 211, 229, 30%);
  vertical-align: baseline;
  text-transform: uppercase;
  display: inline-block;
  padding: var(--rk-p-sm) var(--rk-p-lg);
  white-space: nowrap;
  position: relative;
  text-align: center;
  font-weight: 600;
  line-height: 1em;
  font-size: 11px;
  border: none;
  color: var(--rk-base-white);

  &:hover,
  &:focus {
    background: var(--rk-base-off-grey);
    cursor: default;
    border: none;
  }
}

/*------------------------------------------------------------------------------
// Extend pill element & modifiers
//----------------------------------------------------------------------------*/

%zen-pill {
  @extend .zen-pill;
}
