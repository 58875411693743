/*==============================================================================
// File:        _menu.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared menu component - SASS
//============================================================================*/

.top-item a {
  @extend %zen-link--menu-mobile;
}

/*------------------------------------------------------------------------------
// Offcanvas Styling Features
//----------------------------------------------------------------------------*/

.off-canvas {
  position: relative;
  box-shadow: 0 0 100px rgba(0, 0, 0, 10%);

  .menu-close {
    position: absolute;
    right: 15px;
    top: 33px;
    z-index: 200;
  }

  .moduletable {
    width: 100%;
  }

  .zen-list {
    &:nth-child(1) {
      padding-top: 90px;
    }

    li {
      a {
        font-weight: normal;
        text-decoration: none;
      }

      a:not(.accordion-link).active {
        color: var(--rk-secondary);
        font-weight: bold;
      }
    }
  }
}

/*------------------------------------------------------------------------------
// Zen Menu Styling
//----------------------------------------------------------------------------*/

.zen-menu {
  position: relative;

  .dropdown-toggle {
    color: #000;
    padding: 5px 10px 5px 5px;
    background: transparent;
    border: none;
    margin: 0 !important;

    &:hover {
      background: transparent;
      border: none;
      color: var(--rk-primary);
    }
  }

  .dropdown-menu {
    top: 100%;
  }

  .nav-link {
    @extend %zen-link--menu-main;

    font-family: var(--rk-primary-font-semi-bold);
    text-transform: unset;
  }

  .sub-item {
    display: inline-block;
    padding: var(--rk-p-xs) var(--rk-p-sm);
  }

  /*------------------------------------------------------------------------------
  // Elements
  //----------------------------------------------------------------------------*/

  @include element("main") {
    .mobile {
      box-shadow: var(--rk-shadow-base);
    }

    .nav-header,
    .nav-link {
      @extend %zen-link--menu-main;
    }

    .sub-item {
      display: inline-block;
      padding: var(--rk-p-sm) var(--rk-p-sm);
      width: 100%;
    }

    .offcanvas {
      visibility: visible;
    }

    @include media-breakpoint-down(lg) {
      @include insert-transition(all, .3s, ease-in-out, 0s);

      background: var(--rk-base-white);
      z-index: var(--rk-z-index-top);
      font-weight: normal;
      min-width: 300px;
      position: fixed;
      overflow: auto;
      max-width: 65%;
      right: -480px;
      height: 100%;
      margin: 0;
      top: 0;
    }
  }

  @include element("main-mobile") {
    .zen-list li {
      width: 100%;
    }

    .sppb-col-md-12 {
      padding: 0;
    }

    .moduletable {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .top-item {
      margin-top: 0 !important;
    }

    .offcanvas {
      padding: 30px 0;
    }

    .zen-btn--accordion-mobilemenu {
      margin-bottom: 10px;
    }
  }

  @include element("footer") {
    .nav-header,
    .nav-link {
      background: inherit;
    }
  }
}

/*------------------------------------------------------------------------------
// Mobile menu
//----------------------------------------------------------------------------*/

.zen-menu-open {
  @include media-breakpoint-down(lg) {
    max-height: 100vh;
    overflow: hidden;

    .zen-overlay {
      display: block;
      opacity: 1;
    }

    .sppb-section,
    .zen-header,
    .zen-body {
      z-index: auto !important;
    }

    .sp-page-builder {
      z-index: -1;
    }

    .zen-menu__main {
      transform: translateX(-480px);
      opacity: 1;
    }
  }
}

.sticky-marker {
  position: absolute;
  right: 10px;
  height: 1px;
  width: 1px;
  bottom: 0;
}

.scroll-transition {
  margin-top: 60px;
}

.fixed-menu {
  @include insert-transition(all, .3s, ease, 0s);

  z-index: var(--rk-z-index-middle);
  background: var(--rk-default);
  position: fixed;
  width: 100%;
  top: 0;
}

/*------------------------------------------------------------------------------
// Flyout Menu Styling
//----------------------------------------------------------------------------*/

.zen-menu-flyout {
  .offcanvas-body {
    height: 100vh;
    overflow: scroll;
  }

  .zen-btn--mobile-nav {
    color: var(--rk-base-black);
    padding: 15px !important;

    &:hover {
      color: var(--rk-base-white);
    }
  }

  .zen-btn--flyout-mobilemenu {
    color: var(--rk-base-black);
    padding: var(--rk-p-md) var(--rk-p-df) !important;
    background: var(--rk-base-white);
  }

  .zen-btn-third {
    padding: var(--rk-p-sm) !important;
    text-transform: none;
    font-size: var(--rk-font-size-df);
    border-top: 1px solid var(--rk-base-grey-light);
  }
}

.zen-flyout-back {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.flyout-menu-offcanvas,
.flyout-submenu-offcanvas {
  position: fixed;
  top: 0;
  background: var(--rk-base-white);
  z-index: 100;
  width: 100%;
  max-width: 400px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 50%);

  .deeper {
    position: relative;
  }
}

.zen-menu--footer {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;

  @include media-breakpoint-down(md) {
    grid-template-columns: 1fr;
  }

  .nav-link {
    color: var(--rk-base-white);

    @include media-breakpoint-down(md) {
      padding: var(--rk-p-sm) 0;
    }

    &:hover,
    &:focus {
      color: var(--rk-base-white);
    }
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-menu {
  @extend .zen-menu;
}

%zen-menu__footer {
  @extend .zen-menu__footer;
}
