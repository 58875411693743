/*==============================================================================
// File:        _form.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared form component - SASS
//============================================================================*/

.zen-form {
  position: relative;

  /*==============================================================================
  // Modifiers
  //============================================================================*/
}
