/*==============================================================================
// File:        _variables.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main variables & reusable items - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Global Variables / Bootstrap 5 :root variables
//----------------------------------------------------------------------------*/

// Colour Variables
$primary: #84288E;
$primary-light: #C74394;
$primary-lighter: #C291C5;
$primary-lightest: #E4A5CC;
$primary-dark: #7A1685;
$secondary: #1C1431;
$secondary-light: #342657;
$secondary-dark: #0C0821;
$alternate: #141E1D;
$alternate-light: #273937;
$alternate-dark: #0A0A0A;
$tertiary: #FFC300;
$tertiary-dark: #E6B200;
$tertiary-light: #FFD64D;

// Black, White & Grey
$default-light: #3B3B3B;
$default-dark: #262626;
$default: #212121;
$text-dark: $secondary-dark;
$text-lighter: #707070;
$text-light: #676767;
$text: #585858;
$grey-lightest: #F2F2F2;
$grey-light: #D9D9D9;
$grey: #8F8F8F;

// Bootstrap 5 :root variables
$success: var(--bs-success);
$warning: var(--bs-warning);
$danger: #F00;
$error: var(--bs-red);
$info: var(--bs-info);
$base-red: #B85971;
$base-orange: $secondary;

// More Black white & Grey Variables
$base-white: #FFF;
$base-off-white-dark: #FEF4EE;
$base-off-white: #F4ECF3;
$base-black-light: #191919;
$base-black: #000;
$base-off-grey: #E1D3E5;
$base-grey-lighter: #EAEAEA;
$base-grey-light: #DDD;
$base-grey-darker: #979797;
$base-grey-dark: #AAA;
$base-grey: #CCC;

// Mix Variables
$primary-mix-darkest: mix($primary, $base-black, 30%);
$primary-mix-darker: mix($primary, $base-black, 20%);
$primary-mix-dark: mix($primary, $base-black, 10%);
$primary-mix-lightest: mix($primary, $base-white, 30%);
$primary-mix-lighter: mix($primary, $base-white, 20%);
$primary-mix-light: mix($primary, $base-white, 10%);
$secondary-mix-darkest: mix($secondary, $base-black, 30%);
$secondary-mix-darker: mix($secondary, $base-black, 20%);
$secondary-mix-dark: mix($secondary, $base-black, 10%);
$secondary-mix-lightest: mix($secondary, $base-white, 30%);
$secondary-mix-lighter: mix($secondary, $base-white, 20%);
$secondary-mix-light: mix($secondary, $base-white, 10%);
$alternate-mix-darkest: mix($alternate, $base-black, 30%);
$alternate-mix-darker: mix($alternate, $base-black, 20%);
$alternate-mix-dark: mix($alternate, $base-black, 10%);
$alternate-mix-lightest: $base-off-white;
$alternate-mix-lighter: mix($alternate, $base-white, 20%);
$alternate-mix-light: mix($alternate, $base-white, 10%);

$classic: #44728E;
$light: var(--bs-light);
$dark: $base-black;

// Shadow Variables
$alt-bg-light: rgba($alternate, .42);
$shadow-light: rgba($base-black, .05);
$shadow: rgba($base-black, .2);
$shadow-base: 0 0 20px rgba($base-black, .3);

/*------------------------------------------------------------------------------
// Font Variables / Bootstrap 5 :root variables
//----------------------------------------------------------------------------*/
$default-font-sans: var(--bs-font-sans-serif);
$default-font-mono: var(--bs-font-monospace);
$primary-font: "Outfit", helvetica, arial, sans-serif;
$primary-font-semi-bold: "Outfit SemiBold", helvetica, arial, sans-serif;

$font-size-df: 15px;
$font-size-xl: 18px;
$font-size-lg: 17px;
$font-size-md: 16px;
$font-size-sm: 13px;
$font-size-xs: 11px;

$line-height-df: 18px;
$line-height-xl: 24px;
$line-height-lg: 22px;
$line-height-md: 20px;
$line-height-sm: 19px;
$line-height-xs: 16px;

$letter-space-df: .05em;
$letter-space-lg: 1.17px;
$semi-bold: 500;

/*------------------------------------------------------------------------------
// Icon Variables
//----------------------------------------------------------------------------*/

$icon-size-df: 42px;
$icon-size-xl: 70px;
$icon-size-lg: 60px;
$icon-size-md: 46px;
$icon-size-sm: 36px;
$icon-size-xs: 26px;

/*------------------------------------------------------------------------------
// Layout Variables
//----------------------------------------------------------------------------*/

$m-full-df: 0 0 15px;
$m-full-xl: 0 0 35px;
$m-full-lg: 0 0 30px;
$m-full-md: 0 0 20px;
$m-full-sm: 0 0 10px;
$m-full-xs: 0 0 5px;

$div-p-df: 20px;
$div-p-xl: 40px;
$div-p-lg: 30px;
$div-p-md: 25px;
$div-p-sm: 15px;
$div-p-xs: 5px;

$select-p-df: 15px;
$select-p-xl: 30px;
$select-p-lg: 25px;
$select-p-md: 20px;
$select-p-sm: 10px;
$select-p-xs: 5px;

$link-p-df: 12px;
$link-p-xl: 30px;
$link-p-lg: 20px;
$link-p-md: 15px;
$link-p-sm: 10px;
$link-p-xs: 5px;

$btn-p-df: 15px;
$btn-p-xl: 40px;
$btn-p-lg: 30px;
$btn-p-md: 20px;
$btn-p-sm: 13.6px;
$btn-p-xs: 7px;

$list-m-df: 20px;
$list-m-xl: 30px;
$list-m-lg: 20px;
$list-m-md: 15px;
$list-m-sm: 10px;
$list-m-xs: 5px;

$p-df: 15px;
$p-xl: 40px;
$p-lg: 30px;
$p-md: 20px;
$p-sm: 10px;
$p-xs: 5px;

$m-df: 15px;
$m-xl: 30px;
$m-lg: 25px;
$m-md: 20px;
$m-sm: 10px;
$m-xs: 5px;

$border-radius-df: 8px;
$border-radius-xl: 20px;
$border-radius-lg: 16px;
$border-radius-md: 12px;
$border-radius-sm: 6px;
$border-radius-xs: 4px;

/*------------------------------------------------------------------------------
// Breakpoint Variables
//----------------------------------------------------------------------------*/

$viewport-full-max: 1920px;
$viewport-full-min: 400px;
$viewport-xl: 1600px;
$viewport-lg: 1200px;
$viewport-md: 992px;
$viewport-sm: 768px;
$viewport-xs: 576px;

/*------------------------------------------------------------------------------
// z-index Variables
//----------------------------------------------------------------------------*/

$z-index-df: 10;
$z-index-top: 30;
$z-index-middle: 20;
$z-index-bottom: 5;

/*------------------------------------------------------------------------------
// CSS Variables
//----------------------------------------------------------------------------*/

:root {
  --rk-main-bg-colour: #FFF;
  --rk-image-overlay-bg: rgba($base-black, .2);
  --rk-image-overlay-bg-hover: rgba($base-black, .1);
  --rk-box-shadow: rgba($base-black, .16);
  --rk-border-colour: rgba($base-black, .12);

  // Primary
  --rk-primary: #{$primary};
  --rk-primary-light: #{$primary-light};
  --rk-primary-lighter: #{$primary-lighter};
  --rk-primary-lightest: #{$primary-lightest};
  --rk-primary-dark: #{$primary-dark};

  // Secondary
  --rk-secondary: #{$secondary};
  --rk-secondary-light: #{$secondary-light};
  --rk-secondary-dark: #{$secondary-dark};

  // Alternate
  --rk-alternate: #{$alternate};
  --rk-alternate-light: #{$alternate-light};
  --rk-alternate-dark: #{$alternate-dark};

  // Tertiary
  --rk-tertiary: #{$tertiary};
  --rk-tertiary-dark: #{$tertiary-dark};
  --rk-tertiary-light: #{$tertiary-light};

  // Black, White & Grey
  --rk-default-light: #{$default-light};
  --rk-default-dark: #{$default-dark};
  --rk-default: #{$default};
  --rk-text-dark: #{$text-dark};
  --rk-text-lighter: #{$text-lighter};
  --rk-text-light: #{$text-light};
  --rk-text: #{$text};
  --rk-grey-lightest: #{$grey-lightest};
  --rk-grey-light: #{$grey-light};
  --rk-grey: #{$grey};
  --rk-base-white: #{$base-white};
  --rk-base-off-white-dark: #{$base-off-white-dark};
  --rk-base-off-white: #{$base-off-white};
  --rk-base-black-light: #{$base-black-light};
  --rk-base-black: #{$base-black};
  --rk-base-off-grey: #{$base-off-grey};
  --rk-base-grey-lighter: #{$base-grey-lighter};
  --rk-base-grey-light: #{$base-grey-light};
  --rk-base-grey-darker: #{$base-grey-darker};
  --rk-base-grey-dark: #{$base-grey-dark};
  --rk-base-grey: #{$base-grey};

  // Bootstrap Colours
  --rk-success: var(--bs-success);
  --rk-warning: var(--bs-warning);
  --rk-danger: #F00;
  --rk-error: var(--bs-red);
  --rk-info: var(--bs-info);
  --rk-base-red: #{$base-red};
  --rk-base-orange: #{$base-orange};

  // Mix Colours
  --rk-primary-mix-darkest: mix(var(--rk-primary), var(--rk-base-black), 30%);
  --rk-primary-mix-darker: mix(var(--rk-primary), var(--rk-base-black), 20%);
  --rk-primary-mix-dark: mix(var(--rk-primary), var(--rk-base-black), 10%);
  --rk-primary-mix-lightest: mix(var(--rk-primary), var(--rk-base-white), 30%);
  --rk-primary-mix-lighter: mix(var(--rk-primary), var(--rk-base-white), 20%);
  --rk-primary-mix-light: mix(var(--rk-primary), var(--rk-base-white), 10%);
  --rk-secondary-mix-darkest: mix(var(--rk-secondary), var(--rk-base-black), 30%);
  --rk-secondary-mix-darker: mix(var(--rk-secondary), var(--rk-base-black), 20%);
  --rk-secondary-mix-dark: mix(var(--rk-secondary), var(--rk-base-black), 10%);
  --rk-secondary-mix-lightest: mix(var(--rk-secondary), var(--rk-base-white), 30%);
  --rk-secondary-mix-lighter: mix(var(--rk-secondary), var(--rk-base-white), 20%);
  --rk-secondary-mix-light: mix(var(--rk-secondary), var(--rk-base-white), 10%);
  --rk-alternate-mix-darkest: mix(var(--rk-alternate), var(--rk-base-black), 30%);
  --rk-alternate-mix-darker: mix(var(--rk-alternate), var(--rk-base-black), 20%);
  --rk-alternate-mix-dark: mix(var(--rk-alternate), var(--rk-base-black), 10%);
  --rk-alternate-mix-lightest: var(--rk-base-off-white);
  --rk-alternate-mix-lighter: mix(var(--rk-alternate), var(--rk-base-white), 20%);
  --rk-alternate-mix-light: mix(var(--rk-alternate), var(--rk-base-white), 10%);

  // Random Colours
  --rk-classic: #{$classic};
  --rk-light: var(--bs-light);
  --rk-dark: #{$dark};

  // Shadows
  --rk-alt-bg-light: rgba($alternate, .42);
  --rk-shadow-light: rgba($base-black, .05);
  --rk-shadow: rgba($base-black, .2);
  --rk-shadow-base: 0 0 20px rgba($base-black, .3);
  // Font Variables / Bootstrap 5 :root variables
  --rk-default-font-sans: var(--bs-font-sans-serif);
  --rk-default-font-mono: var(--bs-font-monospace);
  --rk-primary-font: "Outfit", helvetica, arial, sans-serif;
  --rk-primary-font-semi-bold: "Outfit SemiBold", helvetica, arial, sans-serif;
  --rk-font-size-df: #{$font-size-df};
  --rk-font-size-xl: #{$font-size-xl};
  --rk-font-size-lg: #{$font-size-lg};
  --rk-font-size-md: #{$font-size-md};
  --rk-font-size-sm: #{$font-size-sm};
  --rk-font-size-xs: #{$font-size-xs};
  --rk-line-height-df: #{$line-height-df};
  --rk-line-height-xl: #{$line-height-xl};
  --rk-line-height-lg: #{$line-height-lg};
  --rk-line-height-md: #{$line-height-md};
  --rk-line-height-sm: #{$line-height-sm};
  --rk-line-height-xs: #{$line-height-xs};
  // Icon Variables
  --rk-icon-size-df: #{$icon-size-df};
  --rk-icon-size-xl: #{$icon-size-xl};
  --rk-icon-size-lg: #{$icon-size-lg};
  --rk-icon-size-md: #{$icon-size-md};
  --rk-icon-size-sm: #{$icon-size-sm};
  --rk-icon-size-xs: #{$icon-size-xs};
  // Layout Variable
  --rk-m-full-df: #{$m-full-df};
  --rk-m-full-xl: #{$m-full-xl};
  --rk-m-full-lg: #{$m-full-lg};
  --rk-m-full-md: #{$m-full-md};
  --rk-m-full-sm: #{$m-full-sm};
  --rk-m-full-xs: #{$m-full-xs};
  --rk-div-p-df: #{$div-p-df};
  --rk-div-p-xl: #{$div-p-xl};
  --rk-div-p-lg: #{$div-p-lg};
  --rk-div-p-md: #{$div-p-md};
  --rk-div-p-sm: #{$div-p-sm};
  --rk-div-p-xs: #{$div-p-xs};
  --rk-select-p-df: #{$select-p-df};
  --rk-select-p-xl: #{$select-p-xl};
  --rk-select-p-lg: #{$select-p-lg};
  --rk-select-p-md: #{$select-p-md};
  --rk-select-p-sm: #{$select-p-sm};
  --rk-select-p-xs: #{$select-p-xs};
  --rk-link-p-df: #{$link-p-df};
  --rk-link-p-xl: #{$link-p-xl};
  --rk-link-p-lg: #{$link-p-lg};
  --rk-link-p-md: #{$link-p-md};
  --rk-link-p-sm: #{$link-p-sm};
  --rk-link-p-xs: #{$link-p-xs};
  --rk-btn-p-df: #{$btn-p-df};
  --rk-btn-p-xl: #{$btn-p-xl};
  --rk-btn-p-lg: #{$btn-p-lg};
  --rk-btn-p-md: #{$btn-p-md};
  --rk-btn-p-sm: #{$btn-p-sm};
  --rk-btn-p-xs: #{$btn-p-xs};
  --rk-list-m-df: #{$list-m-df};
  --rk-list-m-xl: #{$list-m-xl};
  --rk-list-m-lg: #{$list-m-lg};
  --rk-list-m-md: #{$list-m-md};
  --rk-list-m-sm: #{$list-m-sm};
  --rk-list-m-xs: #{$list-m-xs};
  --rk-p-df: #{$p-df};
  --rk-p-xl: #{$p-xl};
  --rk-p-lg: #{$p-lg};
  --rk-p-md: #{$p-md};
  --rk-p-sm: #{$p-sm};
  --rk-p-xs: #{$p-xs};
  --rk-m-df: #{$m-df};
  --rk-m-xl: #{$m-xl};
  --rk-m-lg: #{$m-lg};
  --rk-m-md: #{$m-md};
  --rk-m-sm: #{$m-sm};
  --rk-m-xs: #{$m-xs};
  --rk-border-radius-df: #{$border-radius-df};
  --rk-border-radius-xl: #{$border-radius-xl};
  --rk-border-radius-lg: #{$border-radius-lg};
  --rk-border-radius-md: #{$border-radius-md};
  --rk-border-radius-sm: #{$border-radius-sm};
  --rk-border-radius-xs: #{$border-radius-xs};
  --rk-letter-space-df: #{$letter-space-df};
  --rk-letter-space-lg: #{$letter-space-lg};
  --rk-semi-bold: #{$semi-bold};
  // Breakpoint Variables
  --rk-viewport-full-max: #{$viewport-full-max};
  --rk-viewport-full-min: #{$viewport-full-min};
  --rk-viewport-xl: #{$viewport-xl};
  --rk-viewport-lg: #{$viewport-lg};
  --rk-viewport-md: #{$viewport-md};
  --rk-viewport-sm: #{$viewport-sm};
  --rk-viewport-xs: #{$viewport-xs};
  // z-index Variables
  --rk-z-index-df: #{$z-index-df};
  --rk-z-index-top: #{$z-index-top};
  --rk-z-index-middle: #{$z-index-middle};
  --rk-z-index-bottom: #{$z-index-bottom};
}

@include color-mode(dark) {
  --rk-primary: #C291C5;
  --rk-secondary: #342657;
  --rk-main-bg-colour: #000;
  --rk-image-overlay-bg: rgba($base-white, .2);
  --rk-image-overlay-bg-hover: rgba($base-white, .1);
  --rk-box-shadow: rgba($base-white, .16);
  --rk-border-colour: rgba($base-white, .12);
}
