/*==============================================================================
// File:        _accordion.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared accordion component - SASS
//============================================================================*/

.zen-accordion {
  padding: 0;

  /*==============================================================================
	// Elements
	//============================================================================*/

  @include element("btn-main") {
    @extend %zen-btn--accordion-main;
  }

  @include element("btn-sub") {
    @extend %zen-btn--accordion-sub;

    background: var(--rk-secondary);
  }

  @include element("content") {
    margin: 0;

    .zen-text,
    p,
    ul li,
    ol li {
      color: var(--rk-default);
      font-size: 16px;
    }
  }

  .zen-btn--accordion-sub {
    .zen-icon {
      font-size: 20px;
      display: none;
      margin: 0 15px 0 0;
    }
  }

  .zen-btn--accordion-sub:not(.collapsed) {
    .zen-icon.minus {
      display: inline-block;
    }
  }

  .zen-btn--accordion-sub.collapsed {
    .zen-icon.plus {
      display: inline-block;
    }
  }

  .dates {
    .zen-text {
      font-size: 15px;
    }
  }

  .zen-pill {
    background: #EEE;
    padding: 8px 20px;
    border-radius: 20px;
    font-size: 15px;
  }
}
