/*==============================================================================
// File:        _article.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared article component - SASS
//============================================================================*/

.zen-article {
  margin: 0 var(--rk-m-df) var(--rk-m-df);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("image") {
    position: relative;
    margin: 0 0 var(--rk-m-xs);
    overflow: hidden;
    display: flex;
  }

  @include element("title") {
    margin: 0 0 var(--rk-m-df);
  }

  @include element("content") {
    flex-direction: column;
    position: relative;
    padding: var(--rk-p-xs);
    display: flex;
  }
}

/*------------------------------------------------------------------------------
// Extend article element & modifiers
//----------------------------------------------------------------------------*/

%zen-article {
  @extend .zen-article;
}

%zen-article__image {
  @extend .zen-article__image;
}

%zen-article__title {
  @extend .zen-article__title;
}

%zen-article__content {
  @extend .zen-article__content;
}
