/*==============================================================================
// File:        _button.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared button component - SASS
//============================================================================*/

.zen-btn {
  @include insert-transition(all, .5s, ease, .1s);

  font-family: var(--rk-primary-font-semi-bold);
  letter-spacing: var(--rk-letter-space-df);
  border-radius: var(--rk-border-radius-df);
  border-color: var(--rk-primary-dark);
  background: var(--rk-primary-dark);
  text-transform: uppercase;
  z-index: var(--rk-z-index-bottom);
  font-size: var(--rk-font-size-df);
  font-weight: var(--rk-semi-bold);
  text-decoration: none;
  display: inline-block;
  padding: var(--rk-p-md) var(--rk-p-xl);
  position: relative;
  color: var(--rk-base-white);
  line-height: 1em;

  &:active,
  &:hover,
  &:focus {
    border-color: var(--rk-primary);
    color: var(--rk-base-off-white);
    text-decoration: none;
    background: var(--rk-primary);
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    background: var(--rk-alternate-mix-light);
    border-color: var(--rk-alternate-mix-light);
    color: var(--rk-text);
    opacity: .6;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("invert") {
    background: var(--rk-primary-light);

    &:hover,
    &:focus {
      background: var(--rk-primary);
    }
  }

  @include modifier("secondary") {
    background: var(--rk-secondary);

    &:hover,
    &:focus {
      background: var(--rk-secondary-light);
    }
  }

  @include modifier("alternate") {
    background: var(--rk-alternate);

    &:hover,
    &:focus {
      background: var(--rk-alternate-light);
    }
  }

  @include modifier("opaque") {
    background: transparent;
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      background: transparent;
      color: var(--rk-base-white);
    }
  }

  @include modifier("default") {
    background: var(--rk-default);

    &:hover,
    &:focus {
      background: var(--rk-default-light);
    }
  }

  @include modifier("dark") {
    background: var(--rk-dark);

    &:hover,
    &:focus {
      background: var(--rk-dark);
      color: var(--rk-secondary);
    }
  }

  @include modifier("warning") {
    background: var(--rk-secondary-light);

    &:hover,
    &:focus {
      background: var(--rk-secondary-light);
    }
  }

  @include modifier("danger") {
    background: var(--rk-primary-light);

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
    }
  }

  @include modifier("link") {
    @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 15px, 25px);

    padding: var(--rk-p-sm) var(--rk-p-sm) var(--rk-p-sm) 0;
    text-transform: capitalize;
    color: var(--rk-primary) !important;
    border: none !important;
    background: transparent;
    align-items: center;
    display: flex;

    &:hover,
    &:focus {
      color: var(--rk-primary-light) !important;
      background: transparent;
    }

    &:after {
      @extend %fontello;

      font-size: var(--rk-icon-size-sm);
      margin: 0 0 0 var(--rk-m-lg);
      position: relative;
      content: "\f178";
      display: block;
    }
  }

  @include modifier("icon-submit") {
    font-size: var(--rk-font-size-lg);
    padding: var(--rk-p-sm) var(--rk-p-md);
    background: var(--rk-primary);
    position: absolute;
    color: var(--rk-base-white);
    right: 1px;
    top: 1px;

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
      color: var(--rk-base-off-white);
      outline: none;
    }
  }

  @include modifier("full-size") {
    width: 100%;
  }

  @include modifier("extra-pad-lg") {
    padding: var(--rk-p-md) var(--rk-p-lg);
  }

  @include modifier("extra-pad-md") {
    padding: var(--rk-p-sm) var(--rk-p-md);
  }

  @include modifier("extra-pad-sm") {
    padding: var(--rk-p-sm) var(--rk-p-sm);
  }

  @include modifier("scroll") {
    border: 1px solid var(--rk-primary-mix-dark);
    border-radius: var(--rk-border-radius-df);
    background: var(--rk-primary);
    color: var(--rk-base-white);
    font-size: 30px;
    display: none;
    padding: 8px;

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
      color: var(--rk-base-white);
    }

    @include adjoin("show-btn") {
      display: flex;
    }

    :before {
      vertical-align: top;
    }
  }

  /*------------------------------------------------------------------------------
	// Outlined Buttons
	//----------------------------------------------------------------------------*/

  @include modifier("outlined-primary") {
    border: 2px solid var(--rk-primary);
    background: var(--rk-base-white);
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border-color: var(--rk-primary-light);
      background: var(--rk-base-white);
      color: var(--rk-primary-light);
    }
  }

  @include modifier("outlined-secondary") {
    border: 2px solid var(--rk-secondary);
    background: var(--rk-base-white);
    color: var(--rk-secondary);

    &:hover,
    &:focus {
      border-color: var(--rk-secondary-light);
      background: var(--rk-base-white);
      color: var(--rk-secondary-light);
    }
  }

  @include modifier("outlined-alternate") {
    border: 2px solid var(--rk-alternate);
    background: var(--rk-base-white);
    color: var(--rk-alternate);

    &:hover,
    &:focus {
      border-color: var(--rk-alternate-light);
      background: var(--rk-base-white);
      color: var(--rk-alternate);
    }
  }

  @include modifier("outlined-gray") {
    border: 2px solid var(--rk-grey);
    background: var(--rk-base-white);
    color: var(--rk-grey);

    &:hover,
    &:focus {
      background: var(--rk-base-grey-light);
      border-color: var(--rk-grey);
      color: var(--rk-base-white);
    }
  }

  @include modifier("mobile-nav") {
    text-transform: uppercase;
    font-weight: normal;
    font-size: var(--rk-font-size-lg);
  }

  /*------------------------------------------------------------------------------
	// Accordion Buttons
	//----------------------------------------------------------------------------*/
  @include modifier("accordion-main") {
    border: none;

    /*Reset Border*/
    border-top: 1px solid var(--rk-default);
    background: var(--rk-base-white);
    text-align: left;
    border-radius: 0;
    padding: var(--rk-p-sm);
    color: var(--rk-text);
    margin: 0;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-secondary);
      outline: none;
    }
  }

  @include modifier("accordion-sub") {
    border: 0;
    background: #F3EBDE;
    text-align: left;
    border-radius: 0;
    padding: 15px;
    color: var(--rk-text);
    margin: 0 0 15px;
    transition: all .3s ease-in-out;

    &:not(.collapse),
    &:hover {
      background: darken(#F3EBDE, 10%) !important;
      outline: none;
      color: var(--rk-text);
    }

    &:focus {
      background: #F3EBDE;
      color: var(--rk-text);
    }

    .zen-accordion {
      .zen-accordion {
        @include modifier("accordion-sub") {
          background: #F3EBDE !important;
          color: var(--rk-text);
        }
      }
    }
  }

  @include modifier("accordion-mobilemenu") {
    position: relative;
    padding: 13.6px 40px 13.5px 10px;

    &:after {
      content: "\e82a";
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      color: var(--rk-grey);
      font-family: fontello;
      transform: rotate(0deg);
    }
  }

  /*------------------------------------------------------------------------------
	// Filter Panel
	//----------------------------------------------------------------------------*/
  @include modifier("navigate") {
    border: none;

    /*Reset Border*/
    border-bottom: 1px solid var(--rk-base-grey);
    justify-content: space-between;
    text-transform: uppercase;
    background: var(--rk-base-white);
    align-items: center;
    font-weight: normal;
    margin: 0 0 var(--rk-m-df);
    text-align: unset;
    color: var(--rk-secondary);
    display: flex;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-secondary);
      outline: none;
    }
  }

  /*------------------------------------------------------------------------------
	// Button style for flyout Menu
	//----------------------------------------------------------------------------*/

  @include modifier("mobile-nav") {
    border-top: 1px solid var(--rk-base-grey-light);
    font-size: var(--rk-font-size-md);
    text-transform: uppercase;
    font-weight: var(--rk-font-bold);
    border-radius: 10px;
    border: none;
    padding: 16px;
    margin-bottom: var(--rk-m-sm);

    &:hover {
      background: var(--rk-primary);
      color: var(--rk-base-white);

      .sub-item {
        color: var(--rk-base-white);
        background: var(--rk-primary);
      }

      &:after,
      a {
        color: var(--rk-base-white);
      }
    }
  }

  .child-sub-item {
    color: var(--rk-text);
    text-decoration: none;
    border-bottom: none;

    &:hover {
      color: var(--rk-base-white) !important;
    }
  }

  @include modifier("flyout-mobilemenu") {
    box-sizing: border-box;
    position: relative;
    padding: var(--rk-p-md);
    color: var(--rk-primary);
    font-weight: var(--rk-font-bold);
    background: var(--rk-base-white);

    &:after {
      @extend %fontello;

      content: "\e84c";
      color: var(--rk-secondary);
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      transform: rotate(0deg);
    }
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/
  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  /*Default btn text size medium*/
  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend button element & modifiers
//----------------------------------------------------------------------------*/

%zen-btn {
  @extend .zen-btn;
}

%zen-btn--secondary {
  @extend .zen-btn--secondary;
}

%zen-btn--alternate {
  @extend .zen-btn--alternate;
}

%zen-btn--opaque {
  @extend .zen-btn--opaque;
}

%zen-btn--default {
  @extend .zen-btn--default;
}

%zen-btn--warning {
  @extend .zen-btn--warning;
}

%zen-btn--danger {
  @extend .zen-btn--danger;
}

%zen-btn--dark {
  @extend .zen-btn--dark;
}

%zen-btn--link {
  @extend .zen-btn--link;
}

%zen-btn--outlined-primary {
  @extend .zen-btn--outlined-primary;
}

%zen-btn--outlined-secondary {
  @extend .zen-btn--outlined-secondary;
}

%zen-btn--full-size {
  @extend .zen-btn--full-size;
}

%zen-btn--accordion-main {
  @extend .zen-btn--accordion-main;
}

%zen-btn--accordion-sub {
  @extend .zen-btn--accordion-sub;
}
