/*==============================================================================
// File:        _type.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main type styling - SASS
//============================================================================*/

h1 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 38px, 48px);

  letter-spacing: var(--rk-letter-space-lg);
  font-family: var(--rk-primary-font-semi-bold);
  color: var(--rk-secondary);

  strong,
  b {
    font-weight: normal;
    color: var(--rk-primary);
  }
}

h2 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 32px, 42px);

  letter-spacing: var(--rk-letter-space-lg);
  font-family: var(--rk-primary-font-semi-bold);
  color: var(--rk-secondary);

  strong,
  b {
    font-weight: normal;
    color: var(--rk-primary);
  }
}

h3 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 26px, 32px);

  letter-spacing: var(--rk-letter-space-lg);
  font-family: var(--rk-primary-font-semi-bold);
  color: var(--rk-secondary);

  strong,
  b {
    font-weight: normal;
    color: var(--rk-primary);
  }
}

h4 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 22px, 26px);

  letter-spacing: var(--rk-letter-space-lg);
  font-family: var(--rk-primary-font-semi-bold);
  color: var(--rk-secondary);

  strong,
  b {
    font-weight: normal;
    color: var(--rk-primary);
  }
}

h5 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 20px, 22px);

  letter-spacing: var(--rk-letter-space-lg);
  font-family: var(--rk-primary-font-semi-bold);
  color: var(--rk-secondary);

  strong,
  b {
    font-weight: normal;
    color: var(--rk-primary);
  }
}

h6 {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 17px, 19px);

  letter-spacing: var(--rk-letter-space-lg);
  font-family: var(--rk-primary-font-semi-bold);
  color: var(--rk-secondary);

  strong,
  b {
    font-weight: normal;
    color: var(--rk-primary);
  }
}

p {
  @include fluid-prop(font-size, var(--rk-viewport-xs), var(--rk-viewport-xl), 16px, 17px);

  font-family: var(--rk-primary-font);
  line-height: 1.8;
}

/*------------------------------------------------------------------------------
// Extend heading tags
//----------------------------------------------------------------------------*/

%h1 {
  @extend h1;
}

%h2 {
  @extend h2;
}

%h3 {
  @extend h3;
}

%h4 {
  @extend h4;
}

%h5 {
  @extend h5;
}

%h6 {
  @extend h6;
}

%p {
  @extend p;
}
