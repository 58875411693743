/*==============================================================================
// File:        _bootstrap.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Bootstrap style overrides - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Example changing the standard spacing to 20px
// Setting all items to maximum width up to 1440px
//----------------------------------------------------------------------------*/

.container {
  padding-right: 25px;
  padding-left: 25px;

  @include media-breakpoint-up(sm) {
    max-width: 760px;
  }

  @include media-breakpoint-up(md) {
    max-width: 900px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1110px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1440px;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  padding-right: 15px;
  padding-left: 15px;
}

/*------------------------------------------------------------------------------
// Navbar overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.navbar .container-fluid {
  display: block;
}

/*------------------------------------------------------------------------------
// Menu overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

/*Allow menu on hover - remove if click is used*/
.zen-menu .dropdown:hover > .dropdown-menu {
  display: block;
}

/*Sub menu position*/
.zen-menu .dropdown-menu .dropdown .dropdown-menu {
  left: 100%;
  margin: 0;
  top: 0%;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--rk-pink);
  background: var(--rk-base-grey-lighter);
}

.zen-menu .dropdown-toggle {
  margin: .2em 0 0 .5em;
}

/*------------------------------------------------------------------------------
// Card feature overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.card {
  @extend %zen-card;
}

.card-image {
  @extend %zen-card__image;
}

.card-title {
  @extend %zen-card__title;
}

.card-header {
  @extend %zen-card__header;
}

.card-body {
  @extend %zen-card__body;
}

.card-info {
  @extend %zen-card__info;
}

.card-footer {
  @extend %zen-card__footer;
}

/*------------------------------------------------------------------------------
// Button module and class overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.btn {
  @extend %zen-btn;

  @include adjoin("btn-primary") {
    @extend %zen-btn;
  }

  @include adjoin("btn-secondary") {
    @extend %zen-btn--secondary;
  }

  @include adjoin("btn-success") {
    @extend %zen-btn--outlined-secondary;
  }
}

a:not([href], [tabindex]) {
  @include adjoin("zen-underline") {
    @extend %zen-link--default-full-underline;
  }

  @include adjoin("zen-link--primary") {
    @extend %zen-link--primary;
  }

  @include adjoin("zen-link--light") {
    @extend %zen-link--light;
  }

  @include adjoin("zen-btn") {
    @extend %zen-btn;
  }

  @include adjoin("zen-btn--outlined-primary") {
    @extend %zen-btn--outlined-primary;
  }

  @include adjoin("zen-btn--accordion-main") {
    @extend %zen-btn--accordion-main;
  }

  @include adjoin("zen-btn--accordion-sub") {
    @extend %zen-btn--accordion-sub;
  }
}

/*------------------------------------------------------------------------------
// Carousel overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    @extend %fontello;

    @include insert-transition(all, .3s, ease, 0s);

    background: rgba(var(--rk-base-white), .15);
    border: 1px solid var(--rk-base-white);
    border-radius: 50%;
    color: var(--rk-base-white);
    line-height: 60px;
    font-size: 30px;
    height: 60px;
    width: 60px;
  }

  &:hover,
  &:focus {
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background: rgba(var(--rk-base-black), .6);
    }
  }
}

.carousel-control-next-icon:before {
  margin: 0 0 0 4px;
  content: "\e812";
}

.carousel-control-prev-icon:before {
  margin: 0 4px 0 0;
  content: "\e811";
}

/*------------------------------------------------------------------------------
// Pagination overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.pagination {
  .page-item {
    @extend %zen-pagination__item;

    .page-link {
      @extend %zen-link;
    }

    @include adjoin("active") {
      .page-link {
        @extend %zen-link--pagination-active;
      }
    }
  }
}

/*------------------------------------------------------------------------------
// Breadcrumbs - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.breadcrumb {
  @extend %zen-breadcrumbs;
}

/*------------------------------------------------------------------------------
// List Group overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.list-group-item {
  border-color: var(--rk-base-grey-light);
}

.list-group-item.active {
  background-color: var(--rk-primary);
  border-color: var(--rk-primary);
}

/*------------------------------------------------------------------------------
// Toast overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.toast {
  z-index: var(--rk-z-index-middle);
  position: fixed;
  left: 15px;
  top: 0;

  .toast-body {
    padding: 5px;
  }
}

/*------------------------------------------------------------------------------
// Form overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.form-control {
  @extend %zen-input;
}

/*------------------------------------------------------------------------------
// Table overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.table {
  color: var(--rk-text);

  thead th {
    border-bottom: 1px solid var(--rk-base-grey);
    color: var(--rk-primary);
  }
}

.table-responsive {
  td,
  th {
    min-width: 140px;
  }
}

.table-bordered {
  border: 1px solid var(--rk-base-grey);

  td,
  th {
    border: 1px solid var(--rk-base-grey);
  }
}

.table-striped {
  tbody tr:nth-of-type(odd) {
    background: var(--rk-light);
  }
}

/*------------------------------------------------------------------------------
// Modal overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.modal {
  @extend %zen-modal;
}
