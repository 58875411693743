/*==============================================================================
// File:        _icon.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared icon component - SASS
//============================================================================*/

.zen-icon {
  font-size: var(--rk-icon-size-df);
  padding: 0;
}
//==================================================================
// Modifiers
//==================================================================

.zen-icon--default {
  color: var(--rk-text);
}

.zen-icon--secondary {
  color: var(--rk-secondary);
}

.zen-icon--light {
  color: var(--rk-base-white);
}

.zen-icon--helper {
  margin: 0 0 0 -1px;
  position: relative;
  font-size: 10px;
  color: var(--rk-text);
  top: -3px;
}

//==================================================================
// Text Sizes
//==================================================================

.zen-icon--text-xl {
  font-size: var(--rk-icon-size-xl);
}

.zen-icon--text-lg {
  font-size: var(--rk-icon-size-lg);
}

.zen-icon--text-md {
  font-size: var(--rk-icon-size-md);
}

.zen-icon--text-sm {
  font-size: var(--rk-icon-size-sm);
}

.zen-icon--text-xs {
  font-size: var(--rk-icon-size-xs);
}
