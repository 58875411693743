/*==============================================================================
// File:        _link.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared link component - SASS
//============================================================================*/
.zen-link {
  color: var(--rk-secondary);

  &:hover,
  &:focus {
    color: var(--rk-secondary);
    text-decoration: none;
    cursor: pointer;
  }

  /*==============================================================================
	// Elements
	//============================================================================*/

  @include element("icon") {
    font-size: var(--rk-icon-size-df);
  }

  /*==============================================================================
	// Modifiers
	//============================================================================*/

  @include modifier("invert") {
    color: var(--rk-secondary);

    &:hover,
    &:focus {
      color: var(--rk-primary);
    }
  }

  @include modifier("default") {
    color: var(--rk-default);

    &:hover,
    &:focus {
      color: var(--rk-default);
    }
  }

  @include modifier("default-underline") {
    color: var(--rk-default);
    border-bottom: 1px solid var(--rk-base-grey);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-default);
    }
  }

  @include modifier("no-underline") {
    text-decoration: none;
    border-bottom: none;
  }

  @include modifier("default-full-underline") {
    text-decoration: underline;
    color: var(--rk-default);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-default);
    }
  }

  @include modifier("light") {
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      color: var(--rk-base-white);
    }
  }

  @include modifier("primary") {
    color: var(--rk-primary);

    &:hover,
    &:focus {
      color: var(--rk-secondary);
    }
  }

  @include modifier("primary-lightest") {
    color: var(--rk-primary-lightest);

    &:hover,
    &:focus {
      color: var(--rk-primary-lighter);
    }
  }

  @include modifier("primary-underline") {
    color: var(--rk-primary);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-secondary);
    }
  }

  @include modifier("primary-full-underline") {
    text-decoration: underline;
    color: var(--rk-primary);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-secondary);
    }
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);

    &:hover,
    &:focus {
      color: var(--rk-secondary-light);
    }
  }

  @include modifier("image-zoom") {
    overflow: hidden;

    img {
      @include insert-transition(transform, .7s, ease, .1s);
    }

    &:hover {
      img {
        transform: scale(1.15);
      }
    }
  }

  @include modifier("search-card") {
    //   Hover Effects for Angular Search Card
    @media (hover: hover) {
      .card-mage:after {
        @include pseudo;

        background: var(--rk-primary);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: .2;

        @include insert-transition(all, .15s, ease-in-out, 0s);
      }

      .zen-card__price,
      .zen-text--price {
        @include insert-transition(all, .15s, ease-in-out, 0s);
      }

      &:hover {
        text-decoration: none;
        color: inherit;

        .card-image:after {
          opacity: 0;
        }

        .zen-card__price {
          background: var(--rk-primary);
          border: 1px solid var(--rk-primary-dark);
        }

        .zen-text--price {
          color: var(--rk-primary);
        }
      }
    }
  }

  @include modifier("related-card") {

    //   Hover Effects for Related Card
    @media (hover: hover) {
      .zen-card__image:after {
        @include pseudo;

        background: var(--rk-primary);
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: .2;

        @include insert_transition(all, .15s, ease-in-out, 0s);
      }

      .zen-card__price,
      .zen-text--price {
        @include insert-transition(all, .15s, ease-in-out, 0s);
      }

      &:hover {
        text-decoration: none;
        color: inherit;

        .zen-card__image:after {
          opacity: 0;
        }

        .zen-card__price {
          background: var(--rk-primary);
          border: 1px solid var(--rk-primary-dark);
        }

        .zen-text--price {
          color: var(--rk-primary);
        }
      }
    }
  }

  @include modifier("facet") {
    padding: 0 0 0 var(--rk-link-p-xl);
    z-index: var(--rk-z-index-middle);
    position: relative;
    line-height: 21px;
    display: block;
  }

  @include modifier("filter-tag") {
    padding: var(--rk-p-xs) 35px var(--rk-p-xs) var(--rk-p-md);
    text-transform: uppercase;
    background: var(--rk-primary);
    border-radius: 30px;
    position: relative;
    color: var(--rk-base-white);
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    display: block;

    &:after {
      @include pseudo;

      @extend %fontello;

      margin: 0 0 0 var(--rk-m-xs);
      content: "\e84f";
      right: 15px;
      top: 9px;
    }
  }

  @include modifier("filter-item") {
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    display: flex;
  }

  @include modifier("facet-heading") {
    text-transform: uppercase;
    font-size: var(--rk-font-size-sm);
    margin: var(--rk-m-sm) 0 var(--rk-m-df);
    font-weight: bold;
    display: block;
    padding: 0;

    .icon-arrow-down {
      display: none;
    }

    .icon-arrow-up {
      display: inline-block;
    }

    @include adjoin("collapsed") {
      .icon-arrow-down {
        display: inline-block;
      }

      .icon-arrow-up {
        display: none;
      }
    }
  }

  @include modifier("pagination-active") {
    border-color: var(--rk-primary);
    background: var(--rk-primary);
    color: var(--rk-base-white);
  }

  /*------------------------------------------------------------------------------
  // Animated Items
  //----------------------------------------------------------------------------*/

  @include modifier("bounce") {
    animation-timing-function: cubic-bezier(.28, .84, .42, 1);
    animation-iteration-count: infinite;
    animation-name: scroll-bounce;
    transform-origin: center;
    animation-duration: 4s;
  }

  @keyframes scroll-bounce {
    0% { transform: translateY(0); }
    30% { transform: translateY(-10px); }
    50% { transform: translateY(0); }
    80% { transform: translateY(-7px); }
    100% { transform: translateY(0); }
  }

  /*------------------------------------------------------------------------------
  // Menu Link Items
  //----------------------------------------------------------------------------*/

  @include modifier("menu-main") {
    line-height: var(--rk-line-height-md);
    font-size: var(--rk-font-size-lg);
    text-decoration: none;
    align-items: center;
    color: var(--rk-alternate);
    padding: var(--rk-p-sm);
    display: flex;

    &:hover,
    &:focus {
      color: var(--rk-alternate-light);
    }
  }

  @include modifier("menu-sub") {
    font-family: var(--rk-primary-font-semi-bold);
    padding: var(--rk-link-p-sm) var(--rk-link-p-md);
    text-transform: capitalize;
    font-size: var(--rk-font-size-df);
    color: var(--rk-secondary-light);

    &:hover,
    &:focus {
      background: var(--rk-base-grey-lighter);
      color: var(--rk-primary-dark);
    }
  }

  @include modifier("menu-mobile") {
    display: inline-block;
    padding: var(--rk-link-p-sm);
    color: var(--rk-primary);
    width: 100%;

    &:hover,
    &:focus {
      color: var(--rk-primary);
    }
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend link element & modifiers
//----------------------------------------------------------------------------*/

%zen-link {
  @extend .zen-link;
}

%zen-link--default {
  @extend .zen-link--default;
}

%zen-link--default-underline {
  @extend .zen-link--default-underline;
}

%zen-link--default-full-underline {
  @extend .zen-link--default-full-underline;
}

%zen-link--primary {
  @extend .zen-link--primary;
}

%zen-link--secondary {
  @extend .zen-link--secondary;
}

%zen-link--light {
  @extend .zen-link--light;
}

%zen-link--pagination-active {
  @extend .zen-link--pagination-active;
}

%zen-link--menu-main {
  @extend .zen-link--menu-main;
}

%zen-link--menu-sub {
  @extend .zen-link--menu-sub;
}

%zen-link--menu-mobile {
  @extend .zen-link--menu-mobile;
}
