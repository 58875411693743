/*==============================================================================
// File:        _hubspot.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Hubspot Form styling & overrides - SASS
//============================================================================*/

.hbspt-form {
  .hs-form-field {
    color: var(--text-light);
  }

  .hs-richtext {
    font-size: var(--font-size-xl);
    margin: 0 0 var(--m-df);
  }

  .hs-button {
    @extend %zen-btn;
  }

  .hs-input {
    @extend %zen-input;
  }
}
