/*==============================================================================
// File:        _sppb.scss
// Package:     Joomla / Mr Zen
// Synopsis:    SP Page Builder overrides - SASS
//============================================================================*/

// Carousel Slider
.sppb-carousel-extended {
  .sppb-carousel-extended-item {
    .sppb-testimonial-carousel-item-content {
      @extend %zen-card;

      background: var(--rk-base-white);
      border-color: var(--rk-secondary-light);
      margin-bottom: 15px !important;
      border-radius: var(--rk-border-radius-df) !important;

      // Remove speech bubble callout
      &:before {
        display: none;
      }
    }

    .sppb-testimonial-carousel-message {
      font-size: 17px;
      color: var(--rk-primary) !important;
    }
  }

  .sppb-carousel-extended-nav-control {
    .nav-control {
      background: var(--rk-secondary-light);
      border-color: var(--rk-secondary-light);
      color: var(--rk-base-white);
    }
  }
}

.sppb-modal-selector {
  span {
    border-style: none !important;
  }
}

// Accordion Overrides
.sppb-panel-faq {
  .sppb-panel-heading {
    border-bottom: 1px solid rgba(var(--rk-base-black), .1);
    text-align: left;
    padding: 10px 0;
  }

  .sppb-panel-title {
    font-size: 22px;
  }

  .sppb-panel-body {
    padding: 30px 0;
  }

  .sppb-panel:last-child .sppb-panel-body {
    border-bottom: 1px solid rgba(var(--rk-base-white), .2);
  }

  .sppb-toggle-direction {
    .fa-chevron-right:before {
      @extend %fontello;

      content: "\e82a";
    }
  }
}

.sppb-addon-accordion {
  .sppb-panel-info .sppb-panel-heading {
    background: none;
    border-top: 1px solid rgba(var(--rk-base-white), .3);
    border-bottom: 1px solid rgba(var(--rk-base-white), .3);
  }

  .sppb-panel-info .sppb-panel-title {
    color: var(--rk-primary-light);
    font-size: 18px;
  }

  .sppb-panel.sppb-panel-info {
    background: none;
    border-left: none;
    border-right: none;
  }
}

// Extending P Style onto SPPB
.sppb-addon-text-block p {
  @extend %p;
}

.sppb-alert-primary {
  background: var(--rk-primary);
  color: var(--rk-base-white);
}

.sppb-progress-bar {
  background: var(--rk-primary);
}

.animated-text-words-wrapper {
  color: var(--rk-primary);
}

.alert-warning {
  color: var(--rk-warning);
}

.sppb-blocknumber-number {
  background-color: var(--rk-primary);
}

.sppb-addon-flickr .sppb-flickr-gallery li a:before {
  background: var(--rk-secondary);
}

.sppb-addon-flickr .sppb-flickr-gallery li a:hover:before {
  background: transparent;
}

.image-effect-zoom-in {
  cursor: pointer;
}

.pricelist-left-image {
  img {
    max-width: 100%;
  }
}

/*------------------------------------------------------------------------------
// Fix SP Page Builder Breakpoint Issue
//----------------------------------------------------------------------------*/

@media screen and (max-width: 768px) and (min-width: 768px) {
  .sppb-addon-image-content {
    .sppb-col-sm-6 {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

/*------------------------------------------------------------------------------
// Fix for adding a new row - SPPB front-end edit mode
//----------------------------------------------------------------------------*/

.sppb-row-bottom-new-row .open .sp-pagebuilder-dropdown-row-layouts {
  display: block;
}

/*------------------------------------------------------------------------------
// Zen SP Page Builder Overrides
//
// Example changing the standard spacing to 20px
// Setting all items to maximum width up to 1440px
//----------------------------------------------------------------------------*/

// Margin top to give space for breadcrumbs
html.homepage #sp-page-builder {
  margin-top: 0 !important;
}

.sppp-addon-content {
  font-size: 15px;
}

html.subpage #sp-page-builder {
  margin-top: 125px;
}

.sppb-row-container {
  @include media-breakpoint-up(sm) {
    max-width: 760px;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    max-width: 900px;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    max-width: 1100px;
    width: 100%;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1440px;
    width: 100%;
  }
}

.sppb-container-inner {
  max-width: 1600px;
  margin: 0 auto;
}

// Adding a class that can be used to set max width on container inner when using container fluid.
//
.inner-container .sppb-container-inner {
  max-width: 1400px;
  margin: 0 auto;
}

.sp-pagebuilder-row,
.sppb-row {
  margin-right: -15px;
  margin-left: -15px;

  @include adjoin("sppb-no-gutter") {
    margin-right: 0;
    margin-left: 0;
  }

  @include media-breakpoint-down(lg) {
    margin-right: 0;
    margin-left: 0;
  }
}

.sppb-col,
.sppb-col-auto,
.sppb-col-lg,
.sppb-col-lg-1,
.sppb-col-lg-10,
.sppb-col-lg-11,
.sppb-col-lg-12,
.sppb-col-lg-2,
.sppb-col-lg-3,
.sppb-col-lg-4,
.sppb-col-lg-5,
.sppb-col-lg-6,
.sppb-col-lg-7,
.sppb-col-lg-8,
.sppb-col-lg-9,
.sppb-col-lg-auto,
.sppb-col-md,
.sppb-col-md-1,
.sppb-col-md-10,
.sppb-col-md-11,
.sppb-col-md-12,
.sppb-col-md-2,
.sppb-col-md-3,
.sppb-col-md-4,
.sppb-col-md-5,
.sppb-col-md-6,
.sppb-col-md-7,
.sppb-col-md-8,
.sppb-col-md-9,
.sppb-col-md-auto,
.sppb-col-sm,
.sppb-col-sm-1,
.sppb-col-sm-10,
.sppb-col-sm-11,
.sppb-col-sm-12,
.sppb-col-sm-2,
.sppb-col-sm-3,
.sppb-col-sm-4,
.sppb-col-sm-5,
.sppb-col-sm-6,
.sppb-col-sm-7,
.sppb-col-sm-8,
.sppb-col-sm-9,
.sppb-col-sm-auto,
.sppb-col-xl,
.sppb-col-xl-1,
.sppb-col-xl-10,
.sppb-col-xl-11,
.sppb-col-xl-12,
.sppb-col-xl-2,
.sppb-col-xl-3,
.sppb-col-xl-4,
.sppb-col-xl-5,
.sppb-col-xl-6,
.sppb-col-xl-7,
.sppb-col-xl-8,
.sppb-col-xl-9,
.sppb-col-xl-auto,
.sppb-col-xs-1,
.sppb-col-xs-10,
.sppb-col-xs-11,
.sppb-col-xs-12,
.sppb-col-xs-2,
.sppb-col-xs-3,
.sppb-col-xs-4,
.sppb-col-xs-5,
.sppb-col-xs-6,
.sppb-col-xs-7,
.sppb-col-xs-8,
.sppb-col-xs-9 {
  padding-right: 15px;
  padding-left: 15px;
}

@include media-breakpoint-up(md) {
  .text-md-start .sppb-text-center {
    text-align: left !important;
  }
}

/*------------------------------------------------------------------------------
// Button Module and Class Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-btn {
  @include adjoin("sppb-btn-default") {
    @extend %zen-btn;
  }

  @include adjoin("sppb-btn-primary") {
    @extend %zen-btn;
    @extend %zen-btn--outlined-primary;
  }

  @include adjoin("sppb-btn-secondary") {
    @extend %zen-btn;
    @extend %zen-btn--secondary;
  }

  @include adjoin("sppb-btn-success") {
    @extend %zen-btn;
    @extend %zen-btn--outlined-secondary;
  }

  @include adjoin("sppb-btn-info") {
    @extend %zen-pill;
  }

  @include adjoin("sppb-btn-warning") {
    @extend %zen-btn;
    @extend %zen-btn--warning;
  }

  @include adjoin("sppb-btn-danger") {
    @extend %zen-btn;
    @extend %zen-btn--danger;
  }

  @include adjoin("sppb-btn-dark") {
    @extend %zen-btn;
    @extend %zen-btn--dark;
  }

  @include adjoin("sppb-btn-link") {
    @extend %zen-btn;
    @extend %zen-btn--link;
  }
}

/*------------------------------------------------------------------------------
// Slideshow Module - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-sp-slider-button .sp-slider-btn-text {
  @extend %zen-btn;
}

.sp-slider .sp-dots,
.sp-slider .sp-nav-control {
  @include media-breakpoint-down(md) {
    height: 40px;
  }
}

.sppb-addon-sp-slider .sp-item .sp-background:after {
  background: rgba(var(--rk-base-black), .1);
}

div.sp-slider .sp-nav-control .nav-control {
  background: rgba(var(--rk-base-white), .15);
}

div[data-arrow-content="icon_only"].sp-slider .sp-nav-control .nav-control {
  font-size: 30px;
  height: 60px;
  width: 60px;
}

div[data-arrow-content="icon_only"] .sp-nav-control .nav-control i {
  line-height: 58px;
}

.sp-slider .sp-nav-control .nav-control.next-control i {
  margin: 0 -2px 0 0;
  right: 5px;
}

.sp-slider .sp-nav-control .nav-control.prev-control i {
  margin: 0 0 0 -2px;
  left: 5px;
}

.sp-dots {
  ul li:before {
    content: none;
  }
}

/*------------------------------------------------------------------------------
// Carousel Pro Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-carousel-pro-text,
.sppb-carousel-text {
  h2 {
    text-shadow: 1px 1px 10px var(--rk-base-black);
  }

  .sppb-carousel-pro-content {
    text-shadow: 1px 1px 5px var(--rk-base-black);
  }
}

/*------------------------------------------------------------------------------
// Testimonials Pro Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-testimonial-pro {
  padding-top: 100px;

  .sppb-testimonial-message {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 30px);

    color: var(--rk-secondary-dark);
    line-height: 40px;
  }

  .sppb-addon-testimonial-pro-client-name {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 16px, 18px);

    font-weight: 400 !important;
  }

  .sppb-carousel-indicators {
    position: absolute;
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    text-align: left;
    bottom: auto;
    width: 100%;
    left: 0;
    top: 0;

    li {
      background: var(--rk-base-white);
      display: inline-block;
      margin: 0 $m-sm 0 0;
      border-radius: 0;
      cursor: pointer;
      text-indent: 0;
      border: none;
      width: 78px;
      height: 7px;

      @include adjoin("active") {
        margin: 0 $m-sm 0 0;
        background: var(--rk-primary);
        width: 78px;
        height: 7px;
      }
    }
  }
}

/*------------------------------------------------------------------------------
// Image Carousel Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-carousel-extended-dots ul li span {
  background: var(--rk-primary);
}

/*------------------------------------------------------------------------------
// Title Style Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-sp-slider-title {
  text-shadow: 1px 1px 10px var(--rk-base-black);
  margin: $m-full-df;

  @include media-breakpoint-down(sm) {
    padding: 0 15%;
  }
}

.sppb-sp-slider-text {
  text-shadow: 1px 1px 5px var(--rk-base-black);

  @include media-breakpoint-down(sm) {
    padding: 0 15%;
  }
}

h1.sppb-sp-slider-title {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 38px, 48px);
}

.zen-heading-light .sppb-addon-title {
  @extend %zen-title--light;
}

.zen-heading-dark .sppb-addon-title {
  @extend %zen-title--dark;
}

.zen-heading-primary .sppb-addon-title {
  @extend %zen-title--primary;
}

.zen-heading-secondary .sppb-addon-title {
  @extend %zen-title--secondary;
}

.zen-capitalize .sppb-addon-title {
  text-transform: capitalize;
}

.sppb-addon-header {
  position: relative;
}

/*------------------------------------------------------------------------------
// Contact Form Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-form-group .sppb-form-control,
.sppb-form-control {
  @extend %zen-input;
}

/*------------------------------------------------------------------------------
// Tab Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-tab {
  @extend %zen-tab;

  .sppb-nav {
    justify-content: center;
    border: none;
  }

  .sppb-nav-modern > li {
    a {
      @extend %zen-tab__link;
    }

    @include adjoin("active") {
      a {
        @extend %zen-tab__link--active;
      }
    }
  }

  .sppb-nav-custom > li {
    a {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px !important, 30px !important);

      background: none !important;
      padding-left: 0 !important;
      color: var(--rk-text) !important;
      border: none !important;
      text-decoration: none;
    }

    @include adjoin("active") {
      a {
        color: var(--rk-primary) !important;
        margin: 0 0 0 $m-xl;
        display: flex;

        &:after {
          @extend %fontello;

          font-size: $icon-size-df;
          margin: 0 0 0 $m-xl;
          position: relative;
          content: "\f178";
          display: block;
          top: 5px;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        @include media-breakpoint-down(xs) {
          margin: 0;
        }
      }
    }
  }

  .sppb-tab-content .sppb-tab-pane {
    @extend %zen-tab__content-pane;
  }
}

/*------------------------------------------------------------------------------
// Table Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.sppb-addon-table-main tbody tr {
  background: var(--rk-light);
}

/*------------------------------------------------------------------------------
// Remove SPPB 'z-index' CSS Class '.zen-remove-zindex'
//----------------------------------------------------------------------------*/

#sp-page-builder .page-content .sppb-section.zen-remove-zindex,
.mod-sppagebuilder .sppb-section.zen-remove-zindex,
#sp-page-builder .sppb-section.zen-remove-zindex {
  z-index: auto;
}

.zen-remove-zindex .sppb-column-addons,
.zen-remove-zindex .sppb-column {
  z-index: auto;
}

/*------------------------------------------------------------------------------
// Blog Flash SBPB Adjustments
//----------------------------------------------------------------------------*/

.sppb-addon-article {
  @extend %zen-article;
}

.sppb-article-img-wrap {
  @extend %zen-article__image;
}

.sppb-article-info-wrap {
  @extend %zen-article__content;
}

.sppb-readmore {
  @extend %zen-btn;
}

.sppb-addon-articles .sppb-col-sm-4 {
  @include media-breakpoint-down(md) {
    margin-top: $m-xl;
    max-width: 100%;
    flex: 0 0 100%;
  }
}

/*------------------------------------------------------------------------------
// Font Awesome Overrides - Mr Zen Customisations
//----------------------------------------------------------------------------*/

.fas,
.fa {
  @include adjoin("fa-star-of-life") {
    @extend %fontello;

    &:before {
      content: "\e899";
    }
  }

  @include adjoin("fa-quote-left") {
    @extend %fontello;

    &:before {
      content: "\e861";
    }
  }
}

/*------------------------------------------------------------------------------
// Fix mobile footer image bug
//----------------------------------------------------------------------------*/

.sppb-addon.sppb-addon-single-image.demo-image img {
  @include media-breakpoint-down(lg) {
    width: 100% !important;
    max-width: 560px;
  }
}

/*------------------------------------------------------------------------------
// Use case tabs mobile style
//----------------------------------------------------------------------------*/

.use-case-tabs {
  @include media-breakpoint-down(sm) {
    .sppb-tab.sppb-custom-tab {
      flex-direction: column;
    }

    .sppb-nav.sppb-nav-custom {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
  }
}
